import { NavLink, Link, useLocation } from "react-router-dom";
import { useState } from "react";

import classes from "./Header.module.css";
import logo from "../../assets/logo.png";

const Header = () => {
  const [openNav, setOpenNav] = useState(false);
  const [navbar, setNavbar] = useState(false);
  const location = useLocation();
  const isActive = openNav ? classes.navActive : "";
  const isNavActive = navbar ? classes.active : "";
  const locationChecker = (location.pathname === "/") ? "" : classes.navColor;
  
  const changeNavBackground = () => {
    if (window.scrollY >= 80) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };
  window.addEventListener("scroll", changeNavBackground);

  return (
    <header>
      <nav className={`${classes.nav} ${locationChecker} ${isNavActive}`}>
        <Link to="/">
          <img src={logo} className={classes.logo} alt="logo" />
        </Link>
        <ul className={`${classes.navLinks} ${isActive}`}>
          <li>
            <NavLink
              onClick={() => setOpenNav(false)}
              exact
              activeClassName={classes.active}
              to="/"
            >
              Home
            </NavLink>
          </li>
          <li>
            <NavLink
              onClick={() => setOpenNav(false)}
              activeClassName={classes.active}
              to="/about"
            >
              Informacje
            </NavLink>
          </li>
          <li>
            <NavLink
              onClick={() => setOpenNav(false)}
              activeClassName={classes.active}
              to="/gallery"
            >
              Galeria
            </NavLink>
          </li>
          <li>
            <NavLink
              onClick={() => setOpenNav(false)}
              activeClassName={classes.active}
              to="/offer"
            >
              Oferta
            </NavLink>
          </li>
          <li>
            <NavLink
              onClick={() => setOpenNav(false)}
              activeClassName={classes.active}
              to="/contact"
            >
              Kontakt
            </NavLink>
          </li>
        </ul>
        <div onClick={() => setOpenNav(!openNav)} className={classes.burger}>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
