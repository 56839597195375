import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";

import StrapiParser from "../../components/StrapiParser";
import Loader from "react-loader-spinner";

import classes from "./Home.module.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import mainImage from "../../assets/homeImage.png";
import slideIcon from "../../assets/slideIcon.png";
import backgroundImage from "../../assets/bg.png";
import rightDecor from "../../assets/rightDecor.png";
import leftDecor from "../../assets/leftDecor.png";

const Home = () => {
  const [loadedHome, setLoadedHome] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const contentRef= useRef(null);

  useEffect(() => {
    setIsLoading(true);
    fetch("https://api.igiproject.pl/Home")
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setLoadedHome(data);
        setIsLoading(false);
      });
  }, []);

  const moveHandler = () => contentRef.current.scrollIntoView()   

  return (
    <>
      <div className={classes.main}>
        <img src={mainImage} alt="background" className={classes.mainImg} />
        <div className={classes.textContainer}>
          <span className={classes.textLine1}>ZAJMIEMY SIĘ</span>
          <span className={classes.mainUnderLine} />
          <span className={classes.textLine2}>
            wykończeniem Twojej nieruchomości
          </span>
          <span className={classes.textLine3}>
            POD <span className={classes.red}>KLUCZ</span>
          </span>
          <Link to="/offer" className={classes.offerButton}>
            OFERTA
          </Link>
          <img
            src={slideIcon}
            alt="slide icon"
            className={classes.slideButton}
            onClick={moveHandler}
          ></img>
        </div>
      </div>
      <div ref={contentRef} className={classes.content}>
        <div className={classes.spinner}>
          <Loader
            type="TailSpin"
            color="#A11E22"
            height={200}
            width={200}
            visible={isLoading}
          />
        </div>
        <img src={backgroundImage} alt="background" className={classes.backgroundImage} />
        <div className={classes.contentText}>
          {!isLoading && (
            <div className={classes.contentWrapper}>
              <span className={classes.welcomeText}>witamy na stronie</span>
              <span className={classes.companyName}>
                IGI <span className={classes.red}>Project</span>
              </span>
              <span className={classes.contentUnderLine}></span>
              <div className={classes.loadedContent}>
                <StrapiParser text={loadedHome.content} />
              </div>
              <div className={classes.contact}>
                <img
                  className={classes.leftDecor}
                  alt="decor"
                  src={leftDecor}
                />
                ZAPRASZAMY DO <Link to="/contact">KONTAKTU</Link>
                <img
                  alt="decor"
                  className={classes.rightDecor}
                  src={rightDecor}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Home;
