import { useState, useEffect, useCallback } from "react";
import Carousel, { Modal, ModalGateway } from "react-images";

import PhotoGallery from "react-photo-gallery";
import classes from "./gallery.module.css";

const Gallery = () => {
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);
  const [loadedPhotos, setLoadedPhotos] = useState([]);

  useEffect(() => {
    fetch("https://api.igiproject.pl/Images")
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setLoadedPhotos(
          data.map((image) => ({
            id: image.id,
            key: image.id.toString(),
            src: `https://api.igiproject.pl${image.image.url}`,
            width: image.image.width,
            height: image.image.height,
            title: image.title,
            alt: image.alt,
          }))
        );
      });
  }, []);

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  return (
    <div className={classes.content}>
      <PhotoGallery columns={5} photos={loadedPhotos} onClick={openLightbox} />
      <ModalGateway>
        {viewerIsOpen ? (
          <Modal onClose={closeLightbox}>
            <Carousel
              currentIndex={currentImage}
              views={loadedPhotos.map((x) => ({
                ...x,
                srcset: x.srcSet,
                caption: x.title,
              }))}
            />
          </Modal>
        ) : null}
      </ModalGateway>
    </div>
  );
};

export default Gallery;
