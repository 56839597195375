import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import Home from "./views/Home";
import About from "./views/About";
import Gallery from "./views/Gallery";
import Contact from "./views/Contact";
import Offer from "./views/Offer";

const Routes = () => {
  return (
    <Switch>
      <Route path="/about">
        <div className="portal-container">
          <About />
        </div>
      </Route>
      <Route path="/gallery">
        <div className="portal-container">
          <Gallery />
        </div>
      </Route>
      <Route path="/offer">
        <div className="portal-container">
          <Offer />
        </div>
      </Route>
      <Route path="/contact">
        <div className="portal-container">
          <Contact />
        </div>
      </Route>
      <Route path="/" exact>
        <Home />
      </Route>
      <Route path="*">
        <Redirect to="/" />
      </Route>
    </Switch>
  );
};

export default Routes;
