import classes from "./Notification.module.css";

const Notification = (props) => {
  return props.trigger ? (
    <div className={classes.popup}>
      <div className={classes.popupInner}>
        <h1>{props.title}</h1>
        <p>{props.description}</p>
        <button
          onClick={() => props.setTrigger(false)}
          className={classes.button}
        >
          {props.buttonText}
        </button>
      </div>
    </div>
  ) : (
    ""
  );
};

export default Notification;
