import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import StrapiParser from "../../components/StrapiParser";
import Loader from "react-loader-spinner";

import classes from "./Offer.module.css";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import offerImage from '../../assets/offerImage.png';


const Offer = () => {
  const [loadedOffers, setLoadedOffers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);


  useEffect(() => {
    setIsLoading(true);
    fetch("https://api.igiproject.pl/Offers")
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setLoadedOffers(
          data.map((offer) => ({
            id: offer.id,
            image: `https://api.igiproject.pl${offer.image.url}`,
            title: offer.title,
            description: offer.description,
            imageTitle: offer.imageTitle,
            alt: offer.alt,
          }))
        );
        setIsLoading(false)
      });
  }, []);
  if (isLoading) {
    return (
      <div className={classes.spinner}>
        <Loader
          type="TailSpin"
          color="#A11E22"
          height={200}
          width={200}
          visible={isLoading}
        />
      </div>
    );
  }

  return loadedOffers.map((offer) => {
    return (
      <div className={classes.content} key={offer.id}>
        <div className={classes.container}>
          <div className={classes.offer}>
            <h1>{offer.title}</h1>
            <div className={classes.offerDescription}><StrapiParser text={offer.description} /></div>
            <div className={classes.wycena}>
              <h1>WYCENA</h1>
              <p>
                Każdy projekt wyceniany jest indywidualnie po oględzinach
                mieszkania, przed rozpoczęciem prac remontowych. W celu
                umówienia się na wycenę zapraszam do{" "}
                <Link to="/contact">kontaktu</Link> poprzez formularz, telefon
                lub email
              </p>
            </div>
          </div>
          <div>
            <img
              className={classes.offerImage}
              // src={offer.image}
              src={offerImage}
              alt="montaż okien"
              title="montaż okien"
            />
          </div>
        </div>
      </div>
    );
  });
};

export default Offer;
