import React from "react";
import { useState, useEffect } from "react";

import StrapiParser from "../../components/StrapiParser";
import Loader from "react-loader-spinner";

import classes from "./About.module.css";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import aboutImage from '../../assets/aboutImage.jpg';


const About = () => {
  const [loadedAbout, setLoadedAbout] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    fetch("https://api.igiproject.pl/About")
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setLoadedAbout(data);
        setIsLoading(false);
      });
  }, []);

  if (isLoading) {
    return (
      <div className={classes.spinner}>
        <Loader
          type="TailSpin"
          color="#A11E22"
          height={200}
          width={200}
          visible={isLoading}
        />
      </div>
    );
  }

  return (
    <div className={classes.content}>
            <div className={classes.about}>
        <h1>{loadedAbout.header}</h1>
        <StrapiParser text={loadedAbout.content}  />
      </div>
      <div className={classes.img}>
        <img
          // src={`https://api.igiproject.pl${loadedAbout.image.url}`}
          src={aboutImage}
          alt="panele"
          title="panele"
          className={classes.aboutImage}
        />
      </div>
    </div>
  );
};

export default About;
