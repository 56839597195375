import { useState, useEffect } from "react";

import Notification from "../../components/Notification";
import useInput from "./use-input";
import Loader from "react-loader-spinner";
import emailjs from "emailjs-com";

import classes from "./Contact.module.css";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

const regExEmail =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const regExPhone = /^\+?[0-9]{3}-?[0-9]{6,12}$/;
const isNotEmpty = (value) => value.trim() !== "";
const isEmail = (value) => regExEmail.test(value.trim());
const isPhone = (value) => regExPhone.test(value.trim());
const isMessage = (value) => value.trim().length > 24;

const Contact = () => {
  const [loadedContact, setLoadedContact] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [successNotification, setSuccessNotification] = useState(false);
  const [failedNotification, setFailedNotification] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    fetch("https://api.igiproject.pl/Contact")
      .then((response) => {
        return response.json();
      })
      .then((data) => setLoadedContact(data));
    setIsLoading(false);
  }, []);

  const addMessageHandler = (messageData) => {
    fetch("https://api.igiproject.pl/Messages", {
      method: "POST",
      body: JSON.stringify(messageData),
      headers: {
        "Content-Type": "application/json",
      },
    }).then((res) => {
      if (res.ok) {
        setSuccessNotification(true);
      } else {
        setFailedNotification(true);
      }
    });
  };

  const {
    value: firstNameValue,
    isValid: firstNameIsValid,
    hasError: firstNameHasError,
    valueChangeHandler: firstNameChangeHandler,
    inputBlurHandler: firstNameBlurHandler,
    reset: resetFirstName,
  } = useInput(isNotEmpty);
  const {
    value: lastNameValue,
    isValid: lastNameIsValid,
    hasError: lastNameHasError,
    valueChangeHandler: lastNameChangeHandler,
    inputBlurHandler: lastNameBlurHandler,
    reset: resetLastName,
  } = useInput(isNotEmpty);
  const {
    value: emailValue,
    isValid: emailIsValid,
    hasError: emailHasError,
    valueChangeHandler: emailChangeHandler,
    inputBlurHandler: emailBlurHandler,
    reset: resetEmail,
  } = useInput(isEmail);
  const {
    value: phoneValue,
    isValid: phoneIsValid,
    hasError: phoneHasError,
    valueChangeHandler: phoneChangeHandler,
    inputBlurHandler: phoneBlurHandler,
    reset: resetPhone,
  } = useInput(isPhone);
  const {
    value: messageValue,
    isValid: messageIsValid,
    hasError: messageHasError,
    valueChangeHandler: messageChangeHandler,
    inputBlurHandler: messageBlurHandler,
    reset: resetMessage,
  } = useInput(isMessage);

  let formIsValid = false;

  if (
    firstNameIsValid &&
    lastNameIsValid &&
    emailIsValid &&
    phoneIsValid &&
    messageIsValid
  ) {
    formIsValid = true;
  }

  const submitHandler = (event) => {
    event.preventDefault();

    if (!formIsValid) {
      return;
    }
    emailjs.sendForm(
      "service_rmx5lq7",
      "template_zqzz33u",
      event.target,
      "user_D7GtBecSjnO9QOufMUxgA"
    );

    const messageData = {
      firstName: firstNameValue,
      lastName: lastNameValue,
      email: emailValue,
      phone: phoneValue,
      message: messageValue,
    };
    addMessageHandler(messageData);
    resetFirstName();
    resetLastName();
    resetEmail();
    resetPhone();
    resetMessage();
  };

  const firstNameClasses = firstNameHasError ? classes.invalidInput : "";
  const lastNameClasses = lastNameHasError ? classes.invalidInput : "";
  const emailClasses = emailHasError ? classes.invalidInput : "";
  const phoneClasses = phoneHasError ? classes.invalidInput : "";
  const messageClasses = messageHasError ? classes.invalidInput : "";

  if (isLoading) {
    return (
      <div className={classes.spinner}>
        <Loader
          type="TailSpin"
          color="#A11E22"
          height={200}
          width={200}
          visible={isLoading}
        />
      </div>
    );
  }

  return (
    <div className={classes.content}>
      <Notification
        trigger={successNotification}
        description="Twoja wiadomość została wysłana, skontaktujemy się z Tobą najszybciej jak to możliwe"
        buttonText="OK"
        title="Wysłano!"
        setTrigger={setSuccessNotification}
      />
      <Notification
        trigger={failedNotification}
        description="Coś poszło nie tak... spróbuj ponownie!"
        buttonText="OK"
        title="Błąd!"
        setTrigger={setFailedNotification}
      />
      <div className={classes.formContainer}>
        <h1>Formularz kontaktowy</h1>
        <form onSubmit={submitHandler}>
          <div className={classes.control}>
            <label htmlFor="firstName">Imię</label>
            <input
              name="firstName"
              type="text"
              id="firstName"
              value={firstNameValue}
              onChange={firstNameChangeHandler}
              onBlur={firstNameBlurHandler}
              className={firstNameClasses}
            />
            {firstNameHasError ? (
              <p className={classes.errorText}>Proszę podać imię.</p>
            ) : (
              <div className={classes.errorEmpty}></div>
            )}
          </div>
          <div className={classes.control}>
            <label htmlFor="lastName">Nazwisko</label>
            <input
              name="lastName"
              type="text"
              id="lastName"
              value={lastNameValue}
              onChange={lastNameChangeHandler}
              onBlur={lastNameBlurHandler}
              className={lastNameClasses}
            />
            {lastNameHasError ? (
              <p className={classes.errorText}>Proszę podać nazwisko.</p>
            ) : (
              <div className={classes.errorEmpty}></div>
            )}
          </div>
          <div className={classes.control}>
            <label htmlFor="email">Adres e-mail</label>
            <input
              name="email"
              type="text"
              id="email"
              value={emailValue}
              onChange={emailChangeHandler}
              onBlur={emailBlurHandler}
              className={emailClasses}
            />
            {emailHasError ? (
              <p className={classes.errorText}>
                Proszę podać poprawny adres email.
              </p>
            ) : (
              <div className={classes.errorEmpty}></div>
            )}
          </div>
          <div className={classes.control}>
            <label htmlFor="phone">Numer Telefonu</label>
            <input
              name="phone"
              type="text"
              id="phone"
              value={phoneValue}
              onChange={phoneChangeHandler}
              onBlur={phoneBlurHandler}
              className={phoneClasses}
            />
            {phoneHasError ? (
              <p className={classes.errorText}>
                Proszę podać poprawny numer telefonu.
              </p>
            ) : (
              <div className={classes.errorEmpty}></div>
            )}
          </div>
          <div className={classes.control}>
            <label htmlFor="message">Wiadomość</label>
            <textarea
              name="message"
              rows={5}
              type="text"
              id="message"
              value={messageValue}
              onChange={messageChangeHandler}
              onBlur={messageBlurHandler}
              className={messageClasses}
            ></textarea>
            {messageHasError ? (
              <p className={classes.errorText}>
                Wiadomość musi posiadać conajmniej 25 znaków.
              </p>
            ) : (
              <div className={classes.errorEmpty}></div>
            )}
          </div>
          <button
            disabled={!formIsValid}
            type="submit"
            className={classes.button}
          >
            Wyślij
          </button>
        </form>
      </div>

      <div className={classes.infoContainer}>
        <h1>Dane kontaktowe</h1>
        <h2>{loadedContact.companyName}</h2>
        <p>Usługi Remontowe</p>
        <p>
          {loadedContact.firstName} {loadedContact.lastName}
        </p>
        <p>Telefon: {loadedContact.phone}</p>
        <p>E-mail: {loadedContact.email} </p>
        <p>NIP: {loadedContact.nip}</p>
        <p>REGON: {loadedContact.regon} </p>
        <div className={classes.map}>
          <iframe
            title="map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d610.3393690201094!2d20.912660738772487!3d52.2732118154185!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x471eca46a3c0272b%3A0x95dfdc60a124e207!2sKsi%C4%99%C5%BCycowa%2060%2C%2001-934%20Warszawa!5e0!3m2!1spl!2spl!4v1626980983823!5m2!1spl!2spl"
            width="100%"
            height="375"
            loading="lazy"
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default Contact;
