import classes from "./Footer.module.css";

const Footer = () => {
  return (
    <footer>
          <div className={classes.copyright}>
          <p>Copyright © 2021 IGI Project</p>
          </div>
    </footer>
  );
};

export default Footer;
